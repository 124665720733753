exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kariera-w-zst-js": () => import("./../../../src/pages/kariera-w-zst.js" /* webpackChunkName: "component---src-pages-kariera-w-zst-js" */),
  "component---src-pages-kontakt-z-zst-js": () => import("./../../../src/pages/kontakt-z-zst.js" /* webpackChunkName: "component---src-pages-kontakt-z-zst-js" */),
  "component---src-pages-onas-js": () => import("./../../../src/pages/onas.js" /* webpackChunkName: "component---src-pages-onas-js" */),
  "component---src-pages-rodo-js": () => import("./../../../src/pages/rodo.js" /* webpackChunkName: "component---src-pages-rodo-js" */),
  "component---src-pages-usluga-energooszczednosc-js": () => import("./../../../src/pages/usluga/energooszczednosc.js" /* webpackChunkName: "component---src-pages-usluga-energooszczednosc-js" */),
  "component---src-pages-usluga-hvac-js": () => import("./../../../src/pages/usluga/hvac.js" /* webpackChunkName: "component---src-pages-usluga-hvac-js" */),
  "component---src-pages-usluga-obsluga-budynkow-js": () => import("./../../../src/pages/usluga/obsluga-budynkow.js" /* webpackChunkName: "component---src-pages-usluga-obsluga-budynkow-js" */),
  "component---src-pages-usluga-system-helpdesk-js": () => import("./../../../src/pages/usluga/system-helpdesk.js" /* webpackChunkName: "component---src-pages-usluga-system-helpdesk-js" */),
  "component---src-pages-usluga-usluga-zintegrowana-js": () => import("./../../../src/pages/usluga/usluga-zintegrowana.js" /* webpackChunkName: "component---src-pages-usluga-usluga-zintegrowana-js" */),
  "component---src-pages-usluga-wsparcie-nieruchomosci-js": () => import("./../../../src/pages/usluga/wsparcie-nieruchomosci.js" /* webpackChunkName: "component---src-pages-usluga-wsparcie-nieruchomosci-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */)
}

